import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import partition from 'lodash/partition';
import difference from 'lodash/difference';
import { AppState } from 'src/state/reducers';

import { TBlog, TLocale } from '../state/systemDocument/types';

import LocalizedField from './LocalizedField';
import Button from './Button';
import { selectors as systemDocumentSelectors, actions as systemDocumentActions } from '../state/systemDocument';
import Field from './Field';
import DialogModal from './DialogModal';
import TagAddModalCheckbox from './TagAddModalCheckbox';
import config from '../config';
import { useSaveChecker } from '../utils/hooks';

type Props = {
  blogID: string;
  close: () => void
  locale: TLocale
};

const Blog = (props: Props) => {
  const { blogID: id, close, locale } = props;

  const blog = useSelector(
    (state: AppState) => systemDocumentSelectors.getBlogsById(state, id),
  ) as TBlog;

  const dispatch = useDispatch();
  const checkSave = useSaveChecker();

  const [state, setState] = useState(blog);
  const [showModal, setShowModal] = useState(false);
  const [tagVisible, setTagVisible] = useState(false);

  const deleteBlog = async () => {
    if (id) {
      dispatch(systemDocumentActions.removeBlogById({ id }));
      close();
    }
  };

  const updateField = (path: string, value: any) => {
    const updatedObject = set(state, path, value);

    setState({
      ...updatedObject,
    });
  };

  const addTag = async (path: string, value: string[]) => {
    updateField(path, [value]);

    setTagVisible(false);
  };

  const handleSave = async () => {
    dispatch(systemDocumentActions.updateBlogs([state]));
    close();
  };

  const initialTagsArray = map(
    partition(
      config.TAGS,
      (tag) => state.tags[0]?.includes(tag.value),
    )[0],
    (item) => item.value,
  );
  const customValuesArray = difference(state.tags[0], initialTagsArray);

  return (
    <section className="trend-indicator">
      <h1 className="heading">
        {get(state, `title[${locale}]`)}
        {' '}
        |
        {' '}
        {id}
      </h1>
      <LocalizedField
        name="title"
        field={state.title}
        onChange={updateField}
        selectedLocale={locale}
      />
      <LocalizedField
        name="excerpt"
        field={state.excerpt}
        onChange={updateField}
        textarea
        selectedLocale={locale}
      />
      <LocalizedField
        name="imageUrl"
        field={state.imageUrl}
        onChange={updateField}
        selectedLocale={locale}
      />
      <section className="images">
        {map(state.imageUrl, (imageUrl, language) => (
          <section key={language}>
            <p className="button">{language}</p>
            <img src={imageUrl} alt={language} />
          </section>
        ))}
      </section>
      <LocalizedField
        name="url"
        field={state.url}
        onChange={updateField}
        selectedLocale={locale}
      />
      {map(state.tags[0], (tag, tagIndex) => (
        <Field
          key={`tags.${tagIndex}`}
          path={`tags.${tagIndex}`}
          value={tag}
          onChange={() => {}}
        />
      ))}

      <TagAddModalCheckbox
        visible={tagVisible}
        hideModal={() => setTagVisible(false)}
        path="tags"
        onSave={async (...args) => checkSave(addTag.bind(this, ...args))}
        initialTagsArray={initialTagsArray}
        customValuesArray={customValuesArray}
      />

      <Button labelText="Edit tags" buttonType="submit" size="medium" onClick={() => setTagVisible(true)} />
      <section className="row-container">
        <Button className="mh10" color="red" labelText="Delete" buttonType="submit" size="medium" onClick={() => setShowModal(true)} />
        <Button labelText="Save" buttonType="submit" size="medium" onClick={async (...args) => checkSave(handleSave.bind(this, ...args))} />
      </section>

      {showModal ? (
        <DialogModal
          title="Are you sure you want to delete this blog?"
          visible={showModal}
          hideModal={() => setShowModal(false)}
          positiveAction={async (...args) => checkSave(deleteBlog.bind(this, ...args))}
        />
      ) : null}
    </section>
  );
};

export default Blog;
