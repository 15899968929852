import React from 'react';
import '../css/TreatmentType.css';

type DeleteConfirmationModalProps = {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const DeleteConfirmationModal:
React.FC<DeleteConfirmationModalProps> = ({ visible, onConfirm, onCancel }) => {
  if (!visible) return null;

  return (
    <>
      <div className="modal-overlay" />
      <div className="modal">
        <div className="modal-content">
          <h2>Confirm Deletion</h2>
          <p>Are you sure you want to delete this option? This action cannot be undone.</p>
          <section className="buttons">
            <button
              type="button"
              className="button contrast"
              onClick={onConfirm}
            >
              Yes, Delete
            </button>
            <button
              type="button"
              className="button cancel"
              onClick={onCancel}
            >
              Cancel
            </button>
          </section>
        </div>
      </div>
    </>
  );
};

export default DeleteConfirmationModal;
