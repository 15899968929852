import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter,
  Route, Switch,
} from 'react-router-dom';

import { Toaster } from 'react-hot-toast';
import { AppState } from './state/reducers';

import Navigation from './components/Navigation';
import TrendIndicators from './screens/TrendIndicators';
import UsefulLinks from './screens/UsefulLinks';
import Glossary from './screens/Glossary';
import TreatmentTypes from './screens/TreatmentTypes';
import Hospitals from './screens/Hospitals';
import ProfileQuestionCategories from './screens/ProfileQuestionCategories';
import ProfileQuestions from './screens/ProfileQuestions';
import DecisionAidQuestions from './screens/DecisionAidQuestions';
import Upload from './screens/Upload';
import Blogs from './screens/Blogs';
import { selectors as authSelectors } from './state/auth';
import { selectors as userSelectors } from './state/user';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './screens/Login';
import DocumentSelection from './screens/DocumentSelection';
import GetGitHubToken from './screens/GetGitHubToken';
import ItemDetails from './screens/ItemDetails';

const Router = () => {
  const auth = useSelector((state: AppState) => authSelectors.get(state));
  const cognitoUser = useSelector((state: AppState) => userSelectors.getUserCognito(state));
  const githubUser = useSelector((state: AppState) => userSelectors.getUserGitHub(state));

  const authSwitch = auth ? (
    <>
      <Navigation />
      <Switch>
        <ProtectedRoute>
          <Route path="/hospitals" component={Hospitals} />
          <Route path="/useful-links" component={UsefulLinks} />
          <Route path="/treatment-types" component={TreatmentTypes} />
          <Route path="/upload" component={Upload} />
          <Route path="/profile-questions" component={ProfileQuestions} />
          <Route path="/profile-question-categories" component={ProfileQuestionCategories} />
          <Route path="/trend-indicators" component={TrendIndicators} />
          <Route path="/glossary" component={Glossary} />
          <Route path="/decision-aid-questions" component={DecisionAidQuestions} />
          <Route path="/item-details/:id/:type/:locale" component={ItemDetails} />
          <Route path="/" component={Blogs} exact />
        </ProtectedRoute>
      </Switch>
    </>
  )
    : (
      <ProtectedRoute>
        <Route path="/" component={DocumentSelection} />
      </ProtectedRoute>
    );

  const unauthSwitch = (
    <Switch>
      <Route path="/auth-github" component={GetGitHubToken} exact />
      <Route path="/" component={Login} />
    </Switch>
  );

  return (
    <>
      <BrowserRouter>
        <main>
          {(githubUser !== '' && cognitoUser !== '') ? authSwitch : unauthSwitch}
        </main>
      </BrowserRouter>
      <Toaster />
    </>
  );
};

export default Router;
