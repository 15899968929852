import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import { Link } from 'react-router-dom';
import ItemDetailsFooter from '../components/ItemDetailsFooter';
import { AppState } from '../state/reducers';

import BlogAddModal from '../components/BlogAddModal';
import {
  selectors as systemDocumentSelectors,
  TBlog,
  TLocale,
} from '../state/systemDocument';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';

import '../css/Blogs.css';

const Blogs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState<TLocale | null>(null);

  const blogs = useSelector((state: AppState) => systemDocumentSelectors.getBlogs(state));
  const itemsPerPage = 10;

  const filteredBlogs = selectedLocale
    ? blogs.filter((blog: TBlog) => blog.title[selectedLocale])
    : blogs;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedBlogs = filteredBlogs.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(filteredBlogs.length / itemsPerPage);

  const handleLocaleChange = (locale: TLocale | null) => {
    setSelectedLocale(locale);
    setCurrentPage(1);
  };

  return (
    <>
      <section className="page">
        <DrawerButtons title="Blogs" showRightButton />
        <button
          type="button"
          className="button contrast add-field"
          onClick={() => setVisible(true)}
        >
          Add Blog
        </button>

        {filteredBlogs.length === 0 ? (
          <div className="empty-state">
            <p>No blogs found for the selected locale.</p>
          </div>
        ) : (
          <div className="blog-list">
            {map(paginatedBlogs, (blog: TBlog) => {
              const locale = map(blog.title, (_, key) => key)[0] as TLocale;
              return (
                <Link
                  to={`/item-details/${blog.id}/blog/${locale}`}
                  key={blog.id}
                  className="blog-item"
                >
                  <img src={blog.imageUrl[locale]} alt={blog.title[locale]} className="blog-thumbnail" />
                  <div>
                    <h3>{blog.title[locale]}</h3>
                    <p>{blog.excerpt[locale]}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </section>

      <DrawerRight
        withLocaleFilter
        selectedLocale={selectedLocale}
        handleLocaleChange={handleLocaleChange}
      />
      <BlogAddModal
        visible={visible}
        hideModal={() => setVisible(false)}
      />
      <ItemDetailsFooter
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};

export default Blogs;
