import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import set from 'lodash/set';
import map from 'lodash/map';
import Dropdown from 'react-dropdown';
import { v4 as uuidv4 } from 'uuid';

import {
  TLocale,
  TLocalizedText,
} from '../state/systemDocument/types';
import LocalizedField from './LocalizedField';
import Modal from './Modal';
import {
  actions as systemDocumentActions,
} from '../state/systemDocument';
import { ReactComponent as IconAdd } from '../assets/icons/Add.svg';
import generateLocalizedField from '../utils/generateLocalizedField';
import getUnixTime from '../utils/getUnixTime';
import Colors from '../theme/Colors';
import Field from './Field';
import TagAddModalCheckbox from './TagAddModalCheckbox';
import { useSaveChecker } from '../utils/hooks';

type Props = {
  visible: boolean;
  hideModal: () => void;
};

const languageOptions = [
  {
    value: 'en-GB',
    label: 'en-GB',
  },
  {
    value: 'nl-NL',
    label: 'nl-NL',
  },
  {
    value: 'en-US',
    label: 'en-US',
  },
  {
    value: 'es-US',
    label: 'es-US',
  },
];

const BlogAddModal = (props: Props) => {
  const { visible, hideModal } = props;
  const [tagVisible, setTagVisible] = useState(false);
  const dispatch = useDispatch();
  const checkSave = useSaveChecker();

  const [selectedLocale, setSelectedLocale] = useState('en-GB');

  const initialState = {
    title: generateLocalizedField([selectedLocale]) as TLocalizedText,
    excerpt: generateLocalizedField([selectedLocale]) as TLocalizedText,
    imageUrl: generateLocalizedField([selectedLocale]) as TLocalizedText,
    url: generateLocalizedField([selectedLocale]) as TLocalizedText,
    tags: [],
  };

  const [state, setState] = useState(initialState);

  const updateField = (path: string, value: any) => {
    const updatedObject = set(state, path, value);

    setState({
      ...updatedObject,
    });
  };

  const resetState = () => setState(initialState);

  const handleSave = async () => {
    dispatch(systemDocumentActions.addBlogs([{
      ...state,
      id: uuidv4(),
      createdAt: getUnixTime(),
      tags: state.tags,
    }]));

    resetState();
    hideModal();
  };

  const addTag = (path: string, value: string[]) => {
    updateField(path, [value]);
    setTagVisible(false);
  };

  useEffect(() => {
    setState(initialState);
  }, [selectedLocale]);

  return (
    <Modal
      visible={visible}
      hideModal={hideModal}
      actions={[
        {
          title: 'Cancel',
          onClick: () => {
            resetState();
            hideModal();
          },
        },
        {
          title: 'Save',
          onClick: async (...args) => checkSave(handleSave.bind(this, ...args)),
          primary: true,
        },
      ]}
    >
      <h2 className="heading-secondary">Select language</h2>
      <Dropdown
        options={languageOptions}
        onChange={(option) => setSelectedLocale(option.value)}
        value={selectedLocale}
        placeholder="Select language"
      />
      <section>
        <h1 className="heading">Add blog</h1>
        <LocalizedField
          name="title"
          field={state.title}
          onChange={updateField}
          isLocaleEnabled={false}
          selectedLocale={selectedLocale as TLocale}
        />
        <LocalizedField
          name="excerpt"
          field={state.excerpt}
          onChange={updateField}
          textarea
          isLocaleEnabled={false}
          selectedLocale={selectedLocale as TLocale}
        />
        <LocalizedField
          name="imageUrl"
          field={state.imageUrl}
          onChange={updateField}
          isLocaleEnabled={false}
          selectedLocale={selectedLocale as TLocale}
        />
        <section className="images">
          {map(state.imageUrl, (imageUrl, language) => (
            imageUrl
              ? (
                <section key={language}>
                  <p className="button">{language}</p>
                  <img src={imageUrl} alt={language} />
                </section>
              )
              : null
          ))}
        </section>
        <LocalizedField
          name="url"
          field={state.url}
          onChange={updateField}
          isLocaleEnabled={false}
          selectedLocale={selectedLocale as TLocale}
        />
        {map(state.tags[0], (tag, index) => (
          <Field
            key={`tags.${index}`}
            path={`tags.${index}`}
            value={tag}
            onChange={() => {}}
          />
        ))}
        <TagAddModalCheckbox
          visible={tagVisible}
          hideModal={() => setTagVisible(false)}
          path="tags"
          onSave={addTag}
          initialTagsArray={[]}
          customValuesArray={[]}
        />
        <button type="button" className="button contrast add-field" onClick={() => setTagVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add tag
        </button>
      </section>
    </Modal>
  );
};

export default BlogAddModal;
