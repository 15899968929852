import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Blog from 'src/components/Blog';

import DrawerRight from '../components/DrawerRight';
import { TLocale } from '../state/systemDocument';
import TrendIndicator from '../components/TrendIndicator';

const ItemDetails = () => {
  const { id, type, locale } = useParams<{ id: string, type: string, locale: TLocale }>();
  const history = useHistory();

  const closeItem = () => {
    history.goBack();
  };

  let content = <p />;

  switch (type) {
    case 'blog':
      content = (
        <Blog
          blogID={id}
          close={closeItem}
          locale={locale}
        />
      );
      break;
    case 'trend':
      content = (
        <TrendIndicator
          trendID={id}
        />
      );
      break;
    default:
      content = <p>Item not found.</p>;
      break;
  }

  return (
    <>
      <section className="page">
        {content}
      </section>
      <DrawerRight />
    </>
  );
};

export default ItemDetails;
