import React, { useState } from 'react';
import map from 'lodash/map';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppState } from '../state/reducers';
import { TLocale } from '../state/systemDocument/types';
import { selectors as systemDocumentSelectors } from '../state/systemDocument';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRight from '../components/DrawerRight';
import ItemDetailsFooter from '../components/ItemDetailsFooter';

const TrendIndicators = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 16;

  const trendIndicators = useSelector(
    (state: AppState) => systemDocumentSelectors.getTrendIndicators(state),
  );

  const trendIndicatorsArr = map(trendIndicators, (item) => item);

  const trendIndicatorsArrSortedByTitle = trendIndicatorsArr.sort((a, b) => a.title['en-GB'].localeCompare(b.title['en-GB']));

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedTrends = trendIndicatorsArrSortedByTitle.slice(
    startIndex,
    startIndex + itemsPerPage,
  );
  const totalPages = Math.ceil(trendIndicatorsArrSortedByTitle.length / itemsPerPage);

  return (
    <>
      <section className="page">
        <DrawerButtons title="Trend indicators" showRightButton />

        <div className="blog-list">
          {map(paginatedTrends, (trend: any) => {
            const locale = map(trend.title, (_, key) => key)[0] as TLocale;
            return (
              <Link
                to={`/item-details/${trend.id}/trend/${locale}`}
                key={trend.id}
                className="blog-item"
              >
                <div>
                  <h3>{trend.title[locale]}</h3>
                </div>
              </Link>
            );
          })}
        </div>

      </section>
      <DrawerRight />
      <ItemDetailsFooter
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};

export default TrendIndicators;
