import React, { useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import set from 'lodash/set';
import uniqBy from 'lodash/uniqBy';
import assignIn from 'lodash/assignIn';
import { useDispatch, useSelector } from 'react-redux';

import { TTrendIndicator } from '../state/systemDocument/types';
import LocalizedField from './LocalizedField';
import Field from './Field';
import DropdownField from './DropdownField';
import ColorField from './ColorField';
import FieldAddModal from './FieldAddModal';
import { ReactComponent as IconAdd } from '../assets/icons/Add.svg';
import { selectors as systemDocumentSelectors, actions as systemDocumentActions } from '../state/systemDocument';

import Colors from '../theme/Colors';
import { useSaveChecker } from '../utils/hooks';
import { AppState } from '../state/reducers';

const thumbsOptions = [
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
];

const dataOptions = [
  {
    value: 'latest',
    label: 'Latest',
  },
  {
    value: 'average',
    label: 'Average',
  },
  {
    value: 'cumulative',
    label: 'Cumulative',
  },
];

type Props = {
  trendID: string;
};

const TrendIndicator = (props: Props) => {
  const { trendID } = props;
  const dispatch = useDispatch();
  const checkSave = useSaveChecker();

  const trendIndicators = useSelector(
    (state: AppState) => systemDocumentSelectors.getTrendIndicators(state),
  );

  const trendIndicator = get(trendIndicators, trendID) as TTrendIndicator;

  const types = uniqBy(
    map(trendIndicators, (item) => ({
      value: item.type,
      label: item.type,
    })),
    'value',
  );

  const [visible, setVisible] = useState(false);

  const updateField = (path: string, value: any) => {
    const updatedObject = set(trendIndicator, path, value);

    dispatch(systemDocumentActions.updateTrendIndicators(updatedObject));
  };

  const addField = async (field: any) => {
    dispatch(systemDocumentActions.updateTrendIndicators(assignIn(trendIndicator, field)));

    setVisible(false);
  };

  return (
    <>
      <section className="trend-indicator">
        <h1 className="heading">
          {get(trendIndicator, 'title["en-GB"]')}
          {' '}
          |
          {' '}
          {trendIndicator.id}
        </h1>
        <button type="button" className="button contrast add-field" onClick={() => setVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add field
        </button>
        <DropdownField
          path="type"
          value={trendIndicator.type}
          onChange={updateField}
          options={types}
        />
        <DropdownField
          path="graphConfig.showThumbs"
          value={`${get(trendIndicator, 'graphConfig.showThumbs', false)}`}
          onChange={updateField}
          options={thumbsOptions}
        />
        <DropdownField
          path="graphConfig.data"
          value={`${get(trendIndicator, 'graphConfig.data', 'latest')}`}
          onChange={updateField}
          options={dataOptions}
        />
        <LocalizedField
          name="title"
          field={trendIndicator.title}
          onChange={updateField}
        />
        <LocalizedField
          name="description"
          field={trendIndicator.description}
          onChange={updateField}
          textarea
        />
        <ColorField
          path="color"
          value={trendIndicator.color}
          onChange={updateField}
        />
        <Field
          path="minimumValue"
          value={trendIndicator.minimumValue}
          onChange={updateField}
        />
        <Field
          path="maximumValue"
          value={trendIndicator.maximumValue}
          onChange={updateField}
        />
        <LocalizedField
          name="minimumValueLabel"
          field={trendIndicator.minimumValueLabel}
          onChange={updateField}
        />
        <LocalizedField
          name="maximumValueLabel"
          field={trendIndicator.maximumValueLabel}
          onChange={updateField}
        />
        {map(trendIndicator.labels, (label, key) => (
          <LocalizedField
            key={`labels.${key}`}
            name={`labels.${key}`}
            field={label}
            onChange={updateField}
          />
        ))}
        {
          trendIndicator.unit
            ? (
              <Field
                path="unit"
                value={trendIndicator.unit}
                onChange={updateField}
              />
            )
            : null
        }
        {
          trendIndicator.labelUnit
            ? (
              <LocalizedField
                name="labelUnit"
                field={trendIndicator.labelUnit}
                onChange={updateField}
              />
            )
            : null
        }
        <Field
          path="step"
          value={trendIndicator.step}
          onChange={updateField}
        />
        {
          trendIndicator.decimals
            ? (
              <Field
                path="decimals"
                value={trendIndicator.decimals}
                onChange={updateField}
              />
            )
            : null
        }
        <Field
          path="order"
          value={trendIndicator.order}
          onChange={updateField}
        />
        <Field
          path="defaultValue"
          value={trendIndicator.defaultValue}
          onChange={updateField}
        />
      </section>
      <FieldAddModal
        visible={visible}
        hideModal={() => setVisible(false)}
        onSave={async (...args) => checkSave(addField.bind(this, ...args))}
      />
    </>
  );
};

export default TrendIndicator;
