import filter from 'lodash/filter';
import includes from 'lodash/includes';

import { AppState } from '../reducers';

const getAll = (state: AppState) => state.systemDocument;

const getUsefulLinks = (state: AppState) => state.systemDocument.usefulLinks;

const getGlossary = (state: AppState) => state.systemDocument.glossary;

const getTreatmentTypes = (state: AppState) => state.systemDocument.treatmentTypes;

const getTrendIndicators = (state: AppState) => state.systemDocument.trendIndicators;

const getHospitals = (state: AppState) => state.systemDocument.hospitals;

const getProfileQuestions = (state: AppState) => state.systemDocument.profileQuestions;

const getProfileQuestionsByIds = (state: AppState, ids: string[]) => (
  filter(state.systemDocument.profileQuestions, (question) => includes(ids, question.id))
);

const getProfileQuestionCategories = (state: AppState) => (
  state.systemDocument.profileQuestionCategories
);

const getDecisionAidQuestions = (state: AppState) => (
  state.systemDocument.decisionAidQuestions
);

const getBlogs = (state: AppState) => state.systemDocument.blogs;

const getBlogsById = (state: AppState, id: string) => (
  state.systemDocument.blogs.find((blog) => blog.id === id)
);

export {
  getAll,
  getUsefulLinks,
  getGlossary,
  getTreatmentTypes,
  getTrendIndicators,
  getHospitals,
  getProfileQuestions,
  getProfileQuestionsByIds,
  getProfileQuestionCategories,
  getDecisionAidQuestions,
  getBlogs,
  getBlogsById,
};
