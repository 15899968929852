import React from 'react';
import Colors from '../theme/Colors';
import { ReactComponent as IconRightArrowNext } from '../assets/icons/RightArrowNext.svg';
import { ReactComponent as IconLeftArrowPrevious } from '../assets/icons/LeftArrowPrevious.svg';

import '../css/Blogs.css';

type TItemDetailsFooter = {
  currentPage: number,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
  totalPages: number
};

const ItemDetailsFooter = ({ currentPage, setCurrentPage, totalPages }: TItemDetailsFooter) => (
  <footer className="pagination-footer">
    <div className="pagination-controls">
      <button
        type="button"
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <IconLeftArrowPrevious fill={Colors.primary} className="icon" />
      </button>
      <span>
        Page
        {' '}
        {currentPage}
        {' '}
        of
        {' '}
        {totalPages}
      </span>
      <button
        type="button"
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <IconRightArrowNext fill={Colors.primary} className="icon" />
      </button>
    </div>
  </footer>
);

export default ItemDetailsFooter;
