/* eslint-disable react/require-default-props */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as IconAccount } from '../assets/icons/Account.svg';
import Colors from '../theme/Colors';
import Drawer from './Drawer';
import { AppState } from '../state/reducers';
import { selectors as userSelectors } from '../state/user';
import Button from './Button';
import { useLogOut } from '../utils/hooks';
import { TLocale } from '../state/systemDocument';

import '../css/Drawer.css';

type TDrawerRightProps = {
  withLocaleFilter?: boolean;
  selectedLocale?: TLocale | null;
  handleLocaleChange?: (locale: TLocale | null) => void;
};

const DrawerRight = (
  { withLocaleFilter = false, selectedLocale, handleLocaleChange }: TDrawerRightProps,
) => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => userSelectors.getUserCognito(state));
  const logOut = useLogOut(dispatch);

  return (
    <Drawer position="right">
      <section className="container">
        <section>
          <h1 className="heading-large accent">User</h1>
          <header className="drawer-header row start center mt10">
            <IconAccount fill={Colors.primary} className="icon" />
            <p className="user-title">{user}</p>
          </header>
        </section>

        {withLocaleFilter ? (
          <div className="locale-filter">
            <h3>Locale filter</h3>
            {['en-GB', 'nl-NL', 'en-US', 'es-US'].map((locale) => (
              <label key={locale} htmlFor={locale} className="locale-option">
                <input
                  id={locale}
                  type="radio"
                  name="locale"
                  value={locale}
                  checked={selectedLocale === locale}
                  onChange={() => handleLocaleChange?.(locale as TLocale)}
                />
                {locale}
              </label>
            ))}
            <label className="locale-option" htmlFor="show-all">
              <input
                id="show-all"
                type="radio"
                name="locale"
                value=""
                checked={selectedLocale === null}
                onChange={() => handleLocaleChange?.(null)}
              />
              Show All
            </label>
          </div>
        ) : null}

        <section className="logout-container">
          <Button buttonType="button" labelText="Log out" onClick={logOut} />
        </section>
      </section>
    </Drawer>
  );
};

export default DrawerRight;
