import React, { useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import assignIn from 'lodash/assignIn';
import union from 'lodash/union';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import classNames from 'classnames';
import { TTreatmentType } from '../state/systemDocument/types';
import LocalizedField from './LocalizedField';
import Field from './Field';
import DropdownField from './DropdownField';
import ColorField from './ColorField';
import FieldAddModal from './FieldAddModal';
import TagAddModal from './TagAddModal';
import { ReactComponent as IconAdd } from '../assets/icons/Add.svg';
import { actions as systemDocumentActions } from '../state/systemDocument';

import Colors from '../theme/Colors';
import { useSaveChecker } from '../utils/hooks';

import '../css/TreatmentType.css';
import DeleteConfirmationModal from './DeleteConfirmationModal';

type LocaleKeys = 'en-GB' | 'nl-NL' | 'en-US' | 'es-US';

const tagToCountryMapping: Record<string, string> = {
  'country:us': 'US',
  'country:nl': 'NL',
  'country:uk': 'GB',
};

const subTypes = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'text',
    label: 'Text',
  },
  {
    value: 'option',
    label: 'Option',
  },
];

type Props = {
  treatmentType: TTreatmentType;
};

const TreatmentType = (props: Props) => {
  const { treatmentType } = props;

  const dispatch = useDispatch();
  const checkSave = useSaveChecker();

  const [visible, setVisible] = useState(false);
  const [tagVisible, setTagVisible] = useState(false);
  const [optionTagPath, setOptionTagPath] = useState('');
  const [newOptionModalVisible, setNewOptionModalVisible] = useState(false);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [deleteOptionIndex, setDeleteOptionIndex] = useState<number | null>(null);
  const [newOptionData, setNewOptionData] = useState<{
    title: Record<LocaleKeys, string>;
    tags: string[];
  }>({
    title: {
      'en-GB': '',
      'nl-NL': '',
      'en-US': '',
      'es-US': '',
    },
    tags: [],
  });

  const updateField = (path: string, value: any) => {
    const updatedObject = set(treatmentType, path, value);
    dispatch(systemDocumentActions.updateTreatmentTypes(updatedObject));
  };

  const addField = async (field: any) => {
    dispatch(systemDocumentActions.updateTreatmentTypes(assignIn(treatmentType, field)));
    setVisible(false);
  };

  const addTag = async (value: string) => {
    const tags = union(treatmentType.tags, [value]);
    dispatch(systemDocumentActions.updateTreatmentTypes(set(treatmentType, 'tags', tags)));
    setTagVisible(false);
  };

  const addOptionTag = async (value: string) => {
    const tags = union(get(treatmentType, optionTagPath), [value]);
    dispatch(systemDocumentActions.updateTreatmentTypes(set(treatmentType, optionTagPath, tags)));
    setOptionTagPath('');
  };

  const addNewOption = () => {
    setNewOptionModalVisible(true);
  };

  const handleSaveNewOption = () => {
    const availableCountries = newOptionData.tags
      .map((tag) => tagToCountryMapping[tag])
      .filter(Boolean);

    const newOption = {
      id: uuidv4(),
      ...newOptionData,
      availableCountries,
    };

    const updatedOptions = [...(treatmentType.options || []), newOption];
    updateField('options', updatedOptions);

    setNewOptionModalVisible(false);
    setNewOptionData({
      title: {
        'en-GB': '',
        'nl-NL': '',
        'en-US': '',
        'es-US': '',
      },
      tags: [],
    });
  };

  const deleteOption = () => {
    if (deleteOptionIndex !== null) {
      const updatedOptions = [...(treatmentType.options || [])];
      updatedOptions.splice(deleteOptionIndex, 1);
      updateField('options', updatedOptions);
    }
    setConfirmDeleteVisible(false);
    setDeleteOptionIndex(null);
  };

  return (
    <>
      <section className="trend-indicator">
        <h1 className="heading">
          {get(treatmentType, 'title["en-GB"]')}
          {' '}
          |
          {treatmentType.id}
        </h1>
        <LocalizedField name="title" field={treatmentType.title} onChange={updateField} />
        <ColorField path="color" value={treatmentType.color} onChange={updateField} />
        <DropdownField
          path="subType"
          value={treatmentType.subType}
          onChange={updateField}
          options={subTypes}
        />
        <Field path="order" value={treatmentType.order} onChange={updateField} />
        {map(treatmentType.tags, (tag, index) => (
          <Field key={`tags.${index}`} path={`tags.${index}`} value={tag} onChange={updateField} />
        ))}
        <button type="button" className="button contrast add-field" onClick={() => setTagVisible(true)}>
          <IconAdd fill={Colors.white} className="icon" />
          Add tag
        </button>
        {treatmentType.subType === 'option' && (
          <>
            <h1 className="heading">Treatment options</h1>
            <button type="button" className="button contrast add-field" onClick={addNewOption}>
              <IconAdd fill={Colors.white} className="icon" />
              Add new option
            </button>
            {map(treatmentType.options, (option, index) => (
              <div key={`options.${index}.title`} className="option">
                <h2 className="heading-secondary">
                  {get(option, 'title["en-GB"]')}
                  {' '}
                  |
                  {option.id}
                </h2>
                <LocalizedField
                  name={`options.${index}.title`}
                  field={option.title}
                  onChange={updateField}
                />
                {map(option.tags, (tag, tagIndex) => (
                  <Field
                    key={`options.${index}.tags.${tagIndex}`}
                    path={`options.${index}.tags.${tagIndex}`}
                    value={tag}
                    onChange={updateField}
                  />
                ))}
                <div className="option-buttons">
                  <button
                    type="button"
                    className="button contrast add-field"
                    onClick={() => setOptionTagPath(`options.${index}.tags`)}
                  >
                    Add tag
                  </button>
                  <button
                    type="button"
                    className="button danger add-field"
                    onClick={() => {
                      setDeleteOptionIndex(index);
                      setConfirmDeleteVisible(true);
                    }}
                  >
                    Delete option
                  </button>
                </div>
                <hr className="divider" />
              </div>
            ))}
          </>
        )}
      </section>
      <FieldAddModal
        visible={visible}
        hideModal={() => setVisible(false)}
        onSave={async (...args) => checkSave(addField.bind(this, ...args))}
      />
      <TagAddModal
        visible={tagVisible}
        hideModal={() => setTagVisible(false)}
        onSave={async (...args) => checkSave(addTag.bind(this, ...args))}
      />

      {newOptionModalVisible && (
        <>
          <div className="modal-overlay" />
          <div className="modal">
            <div className="modal-content">
              <h2>Add New Option</h2>
              {Object.keys(newOptionData.title).map((locale) => (
                <Field
                  key={`title.${locale}`}
                  path={`title.${locale}`}
                  value={newOptionData.title[locale as LocaleKeys]}
                  onChange={(path, value) => setNewOptionData((prev) => ({
                    ...prev,
                    title: { ...prev.title, [locale as LocaleKeys]: value },
                  }))}
                />
              ))}
              {newOptionData?.tags.length > 0 && <h3>Tags</h3>}
              {map(newOptionData.tags, (tag, index) => (
                <div key={`tags.${index}`} className="tag-container">
                  <div className="tag">
                    <Field
                      path={`tags.${index}`}
                      value={tag}
                      onChange={(path, value) => setNewOptionData((prev) => ({
                        ...prev,
                        tags: prev.tags.map((t, i) => (i === index ? value : t)),
                      }))}
                    />
                  </div>
                  <div className="option-buttons">
                    <button
                      type="button"
                      className="button danger add-field mb10"
                      onClick={() => setNewOptionData((prev) => ({
                        ...prev,
                        tags: prev.tags.filter((_, i) => i !== index),
                      }))}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
              <button
                type="button"
                className="button contrast"
                onClick={() => setNewOptionData((prev) => ({ ...prev, tags: [...prev.tags, ''] }))}
              >
                Add Tag
              </button>
              <section className="buttons">
                <button
                  type="button"
                  className={classNames('button', { destructive: false, accent: true })}
                  onClick={handleSaveNewOption}
                >
                  Save
                </button>
                <button
                  className={classNames('button', { destructive: true, accent: false })}
                  type="button"
                  onClick={() => setNewOptionModalVisible(false)}
                >
                  Cancel
                </button>
              </section>
            </div>
          </div>
        </>
      )}

      <TagAddModal
        visible={!isEmpty(optionTagPath)}
        hideModal={() => setOptionTagPath('')}
        onSave={async (...args) => checkSave(addOptionTag.bind(this, ...args))}
      />

      <DeleteConfirmationModal
        visible={confirmDeleteVisible}
        onConfirm={deleteOption}
        onCancel={() => setConfirmDeleteVisible(false)}
      />
    </>
  );
};

export default TreatmentType;
