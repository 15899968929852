/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import keys from 'lodash/keys';
import first from 'lodash/first';
import get from 'lodash/get';
import Dropdown from 'react-dropdown';

import { ReactComponent as IconChevronUp } from '../assets/icons/ChevronUp.svg';
import { ReactComponent as IconChevronDown } from '../assets/icons/ChevronDown.svg';
import Colors from '../theme/Colors';
import { TLocale } from '../state/systemDocument';

type Props = {
  field: {
    [locale: string]: string;
  };
  name: string;
  onChange: (path: string, value: any) => void;
  textarea?: boolean;
  isLocaleEnabled?: boolean;
  selectedLocale?: TLocale;
};

const LocalizedField = (props: Props) => {
  const {
    field,
    name,
    onChange,
    textarea = false,
    isLocaleEnabled = true,
    selectedLocale,
  } = props;
  const locales = keys(field);

  const [locale, setLocale] = useState<string>(
    isLocaleEnabled !== undefined ? selectedLocale as string : first(locales) as string,
  );
  const [visible, setVisible] = useState(false);

  const options = map(locales, (l) => ({
    value: l,
    label: l,
  }));

  const path = `${name}.${locale}`;
  const value = get(field, locale);

  const TextInput = (
    <input
      value={value}
      onChange={(event) => onChange(path, event.currentTarget.value)}
      name={path}
      id={path}
    />
  );

  const TextArea = (
    <textarea
      value={value}
      onChange={(event) => onChange(path, event.currentTarget.value)}
      name={path}
      id={path}
    />
  );

  useEffect(() => {
    if (selectedLocale !== undefined) {
      setLocale(selectedLocale as string);
    } else {
      setLocale(first(locales) as string);
    }
  }, [selectedLocale]);

  return (
    <section className="field">
      <button type="button" onClick={() => setVisible(!visible)}>
        <h2 className="heading-secondary">{name}</h2>
        {
          visible
            ? <IconChevronUp fill={Colors.lightGray} className="icon small" />
            : <IconChevronDown fill={Colors.lightGray} className="icon small" />
        }
      </button>
      {
        visible
          ? (
            <section className="row">
              {isLocaleEnabled ? (
                <Dropdown
                  options={options}
                  onChange={(option) => setLocale(option.value)}
                  value={locale}
                  placeholder="Select language"
                />
              ) : null}
              <label htmlFor={path} key={path}>
                {textarea ? TextArea : TextInput}
              </label>
            </section>
          )
          : null
      }
    </section>
  );
};

export default LocalizedField;
